import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94')
];

export const server_loads = [0,2,4,5,6,7,8];

export const dictionary = {
		"/(auth)": [~10,[2],[3]],
		"/(auth)/chyby-systemu": [~11,[2],[3]],
		"/(auth)/chyby-systemu/nova": [13,[2],[3]],
		"/(auth)/chyby-systemu/[id]": [~12,[2],[3]],
		"/(auth)/emaily": [~14,[2],[3]],
		"/(auth)/flotila": [~15,[2],[3]],
		"/(auth)/flotila/[id]": [~16,[2],[3]],
		"/(auth)/kontakty/partneri": [~17,[2],[3]],
		"/(auth)/kontakty/partneri/novy": [24,[2],[3]],
		"/(auth)/kontakty/partneri/[id]": [18,[2,4],[3]],
		"/(auth)/kontakty/partneri/[id]/edit": [19,[2,4],[3]],
		"/(auth)/kontakty/partneri/[id]/ridic/novy": [21,[2,4],[3]],
		"/(auth)/kontakty/partneri/[id]/ridic/[driverId]": [~20,[2,4],[3]],
		"/(auth)/kontakty/partneri/[id]/vuz/novy": [23,[2,4],[3]],
		"/(auth)/kontakty/partneri/[id]/vuz/[fleetId]": [~22,[2,4],[3]],
		"/(auth)/kontakty/ridici": [~25,[2],[3]],
		"/(auth)/kontakty/ridici/[id]": [~26,[2],[3]],
		"/(auth)/kontakty/uzivatele": [~27,[2],[3]],
		"/(auth)/kontakty/uzivatele/novy": [~30,[2],[3]],
		"/(auth)/kontakty/uzivatele/[id]": [28,[2,5],[3]],
		"/(auth)/kontakty/uzivatele/[id]/order": [~29,[2,5],[3]],
		"/(auth)/kontrola/zakladny": [~31,[2],[3]],
		"/(auth)/nastaveni/platebni-metody": [~32,[2],[3]],
		"/(auth)/nastaveni/platebni-metody/nova": [34,[2],[3]],
		"/(auth)/nastaveni/platebni-metody/[id]": [~33,[2],[3]],
		"/(auth)/nastaveni/system": [~35,[2],[3]],
		"/(auth)/nastaveni/system/nove": [37,[2],[3]],
		"/(auth)/nastaveni/system/[id]": [~36,[2],[3]],
		"/(auth)/objednavky/bus": [~46,[2],[3]],
		"/(auth)/objednavky/dnesni": [~47,[2],[3]],
		"/(auth)/objednavky/nadchazejici": [~48,[2],[3]],
		"/(auth)/objednavky/nedokoncene": [~49,[2],[3]],
		"/(auth)/objednavky/nedokoncene/[id]": [~50,[2],[3]],
		"/(auth)/objednavky/nepotvrzene": [~51,[2],[3]],
		"/(auth)/objednavky/nova": [~52,[2],[3]],
		"/(auth)/objednavky/posledni": [~53,[2],[3]],
		"/(auth)/objednavky/zitra": [~54,[2],[3]],
		"/(auth)/objednavky/zrusene": [~55,[2],[3]],
		"/(auth)/objednavky/[id]": [38,[2,6],[3]],
		"/(auth)/objednavky/[id]/edit": [~39,[2,6],[3]],
		"/(auth)/objednavky/[id]/nabidka": [40,[2,6],[3]],
		"/(auth)/objednavky/[id]/obnovit": [41,[2,6],[3]],
		"/(auth)/objednavky/[id]/platba": [~42,[2,6],[3]],
		"/(auth)/objednavky/[id]/potvrdit": [43,[2,6],[3]],
		"/(auth)/objednavky/[id]/ridic": [44,[2,6],[3]],
		"/(auth)/objednavky/[id]/storno": [45,[2,6],[3]],
		"/(auth)/obsah/blog/(posts)": [~56,[2,7],[3]],
		"/(auth)/obsah/blog/kategorie": [59,[2,7],[3]],
		"/(auth)/obsah/blog/kategorie/nova": [61,[2,7],[3]],
		"/(auth)/obsah/blog/kategorie/[id]": [~60,[2,7],[3]],
		"/(auth)/obsah/blog/(posts)/novy": [58,[2,7],[3]],
		"/(auth)/obsah/blog/(posts)/[id]": [~57,[2,7],[3]],
		"/(auth)/obsah/odpovedi": [~62,[2],[3]],
		"/(auth)/obsah/odpovedi/nova": [64,[2],[3]],
		"/(auth)/obsah/odpovedi/[id]": [~63,[2],[3]],
		"/(auth)/obsah/otazky": [~65,[2],[3]],
		"/(auth)/obsah/otazky/nova": [67,[2],[3]],
		"/(auth)/obsah/otazky/[id]": [~66,[2],[3]],
		"/(auth)/obsah/stranky": [~68,[2],[3]],
		"/(auth)/obsah/stranky/nova": [~70,[2],[3]],
		"/(auth)/obsah/stranky/[id]": [~69,[2],[3]],
		"/(auth)/obsah/texty": [~71,[2],[3]],
		"/(auth)/obsah/texty/novy": [73,[2],[3]],
		"/(auth)/obsah/texty/[id]": [~72,[2],[3]],
		"/(auth)/platby": [~74,[2],[3]],
		"/(auth)/platby/[id]": [~75,[2],[3]],
		"/(auth)/pokryti/staty": [~76,[2],[3]],
		"/(auth)/pokryti/staty/[id]": [~77,[2],[3]],
		"/(auth)/pokryti/zakladny": [~78,[2],[3]],
		"/(auth)/pokryti/zakladny/nova": [~82,[2],[3]],
		"/(auth)/pokryti/zakladny/[id]": [79,[2,8],[3]],
		"/(auth)/pokryti/zakladny/[id]/edit": [80,[2,8],[3]],
		"/(auth)/pokryti/zakladny/[id]/vozy/[carId]": [~81,[2,8],[3]],
		"/(guest)/prihlasit": [~94],
		"/(auth)/recenze": [~83,[2],[3]],
		"/(auth)/recenze/nova": [85,[2],[3]],
		"/(auth)/recenze/[id]": [~84,[2],[3]],
		"/(auth)/reporty/denne/objednavky": [~86,[2,9],[3]],
		"/(auth)/reporty/denne/transfery": [~87,[2,9],[3]],
		"/(auth)/reporty/mesicne/objednavky": [~88,[2,9],[3]],
		"/(auth)/reporty/mesicne/transfery": [~89,[2,9],[3]],
		"/(auth)/reporty/partneri": [~90,[2,9],[3]],
		"/(auth)/reporty/platby": [~91,[2,9],[3]],
		"/(auth)/reporty/ridici": [~92,[2,9],[3]],
		"/(auth)/reporty/transfery": [~93,[2,9],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';